<template>
  <!-- Replace Vue logo with custom logo -->
  <img alt="Remunera Logo" src="./assets/logo.png">
  <!-- HomePage component with a welcome message -->
  <HomePage msg="Welcome to Your Vue.js App"/>
</template>

<script>
// Import HomePage from the components directory
import HomePage from './components/HomePage.vue'

export default {
  name: 'App',
  components: {
    HomePage // Register HomePage component
  }
}
</script>

<style>
/* Set the global font and background styles */
body {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  background-color: #121212; /* Dark background */
  color: #ffffff; /* Light text for readability */
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin-top: 60px;
}
</style>
