<template>
  <div class="salary-calculator">
    <input type="text" v-model="searchQuery" placeholder="Search job titles" @input="filterTitles" />
    
    <!-- Dropdown for selecting a job from the filtered list -->
    <select v-if="filteredTitles.length" v-model="selectedJob">
      <option disabled value="">Select a job title</option>
      <option v-for="title in filteredTitles" :key="title" :value="title">{{ title }}</option>
    </select>
    <p v-else>No matching job titles found.</p>
    
    <!-- Show selected job -->
    <h3 v-if="selectedJob">Selected Job: {{ selectedJob }}</h3>

    <!-- Percentile selection -->
    <select v-model="selectedPercentile">
      <option disabled value="">Select Percentile</option>
      <option>25th</option>
      <option>50th</option>
      <option>75th</option>
    </select>

    <!-- Button to calculate salary -->
    <button @click="calculateSalary">Calculate Salary</button>

    <!-- Display calculated salary -->
    <div v-if="calculatedSalary">
      <p>Original Salary: {{ calculatedSalary.original_salary }}</p>
      <p>Increased Salary: {{ calculatedSalary.increased_salary }}</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'JobSalaryCalculator',
  data() {
    return {
      searchQuery: '',
      allTitles: [],
      selectedJob: '',
      selectedPercentile: '',
      calculatedSalary: null,
    };
  },
  computed: {
    filteredTitles() {
      return this.searchQuery.length < 1 ? this.allTitles : 
        this.allTitles.filter(title => 
          title.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
    }
  },
  methods: {
    filterTitles() {
      // Load job titles if not already loaded
      if (!this.allTitles.length) {
        axios.get('https://54.147.182.220:4000/api/job_titles')
          .then(response => {
            this.allTitles = response.data;
          })
          .catch(error => {
            console.error('Error fetching job titles:', error);
          });
      }
    },
    calculateSalary() {
      if (!this.selectedJob || !this.selectedPercentile) {
        alert('Please select a job title and percentile.');
        return;
      }
      axios.post('https://54.147.182.220:4000/api/calculate_salary', {
        job_title: this.selectedJob,
        percentage: this.selectedPercentile
      }).then(response => {
        this.calculatedSalary = response.data;
      }).catch(error => {
        console.error('Failed to calculate salary:', error);
        this.calculatedSalary = null;
      });
    }
  },
  mounted() {
    this.filterTitles(); // Initially load all job titles
  }
}
</script>

<style scoped>
.salary-calculator {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #333;
  background: #c2e2e2;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.salary-calculator input, .salary-calculator select, .salary-calculator button {
  width: 90%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #c5c5c5;
  border-radius: 4px;
  font-size: 16px;
}

.salary-calculator button {
  background-color: #2b837c;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.salary-calculator button:hover {
  background-color: #39a1a3;
}

.salary-calculator select {
  appearance: none; /* Removes default styling by the browser */
  background-color: rgb(191, 216, 219);
  cursor: pointer;
}

h3 {
  font-size: 18px;
  color: #4cacaf;
  margin-top: 0;
}

p {
  margin-top: 0;
  font-size: 16px;
  color: #666;
}
</style>
